.multiSlider {
  margin-top: 94px;
  
  .splide__arrow {
    background-color: transparent;

    &--prev {
      left: -2em;
    }

    &--next {
      right: -2em;
    }
  }
}

#endMultiSlider {
  margin-bottom: 150px;

  @media (max-width: 992px) {
    margin-bottom: 50px;
  }
}
.swiper {
  width: 100%;
  height: 100%;
  margin-bottom: 100px;
}

.swiper-slide {
  text-align: center;
  font-size: 18px;
  // background: #fff;
  // height: 540px;
  /* Center slide text vertically */
  display: -webkit-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
  overflow: hidden;
  height: 250px !important;
  // &:hover{
  //   cursor: zoom-in;
  //   img{
  //     transform: scale(1.2);
  //   }
  // }
  
}

.swiper-slide img {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
  transition: .5s ease;
 
}
@media screen and (max-width:576px) {
  .swiper-slide{
    height: 490px;
    img{
      height: 100%;
    }
  }
}