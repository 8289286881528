.newsSlider {
  margin-top: 108px;

  &__items {
    background-color: #fff;
    border-radius: 8px;

    &--imgBox {
      img {
        border-radius: 8px;
        height: 272px;
        width: 100%;
        object-fit: cover;

        @media (max-width: 992px) {
          height: auto;
        }
      }
    }

    &--text {
      padding: 0 33px;
      display: -webkit-box;
      line-height: 24px;
      -webkit-line-clamp: 3;
      -webkit-box-orient: vertical;
      text-overflow: ellipsis;
      overflow: hidden;
      margin-bottom: 46px;

      p {
        font-weight: 500;
        font-size: 14px;
        line-height: 17px;
        color: #000000;
      }
    }

    &--info {
      box-shadow: 0px 12px 39px rgba(0, 0, 0, 0.06);

      h3 {
        font-weight: 600;
        font-size: 18px;
        line-height: 22px;
        color: #000000;
        display: flex!important;
        flex-direction: column;
        padding: 30px 33px;
        display: -webkit-box;
        line-height: 24px;
        -webkit-line-clamp: 3;
        -webkit-box-orient: vertical;
        text-overflow: ellipsis;
        overflow: hidden;
        height: 111px;

        @media (max-width: 992px) {
          height: auto;
        }

        span {
          display: inline-block;
          font-size: 18px;
          line-height: 22px;
          color: #bf0807;
          margin-top: 4.2px;
        }
      }

      a {
        bottom: 0;
        left: 0;
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        font-weight: 600;
        font-size: 14px;
        line-height: 17px;
        color: #bf0807;
        border-top: 1px solid rgba(95, 92, 91, 0.29);
        padding: 24px 10px;

        svg {
          margin-left: 7px;
        }
      }
    }
  }
}
