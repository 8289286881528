.homePage {
  &__sliderLayout {
    background: rgba(0, 0, 0, 0.2);
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: 1;
  }

  .swiper {
    width: 100%;
    height: 100%;
  }
  
  .home_slide_swiper {
    text-align: center;
    font-size: 18px;
    background: #fff;
   height: 646px!important;
    /* Center slide text vertically */
    display: -webkit-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    -webkit-justify-content: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    -webkit-align-items: center;
    align-items: center;
    @media screen and (max-width:1205px) {
      height: 100% !important;
    }
  }
  
  
  .swiper-slide img {
    display: block;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  

  &__slider {
    height: 100%;
    position: relative;

    @media (max-width: 1201px) {
      height: 346px;
    }

    .splide__arrows {
      position: absolute;
      top: 50%;
      left: 50%;
      -webkit-transform: translate(-50%, -50%);
      -moz-transform: translate(-50%, -50%);
      -ms-transform: translate(-50%, -50%);
      -o-transform: translate(-50%, -50%);
      transform: translate(-50%, -50%);
      width: 1328px;
      z-index: 5;

      @media (max-width: 1350px) {
        width: 100%;
      }

      @media (max-width: 1201px) {
        display: none;
      }
    }

    .splide__pagination {
      bottom: 32px;

      li {
        &:not(:last-child) {
          margin-right: 10px;
        }

        button {
          margin: 0;
          width: 8px;
          height: 8px;
          opacity: 1;
          background: #e9e9e9;

          &.is-active {
            background: rgba(0, 0, 0, 0.81);
            transform: unset;
          }
        }
      }
    }

    .splide__arrow {
      border: 1px solid rgba(0, 0, 0, 0.73);
      box-sizing: border-box;
      border-radius: 4px;
      width: 41px;
      height: 41px;
      background-color: transparent;
    }

    .splide__track {
      height: 646px;

      @media (max-width: 1201px) {
        height: 346px;
      }

      img {
        width: 100%;
        height: 100%;
        @media screen and (max-width: 768px) {
           object-fit: cover;
        }
        @media screen and (min-width: 1200px) {
          object-fit: cover;
       }
      }
    }
  }

  &__count {
    margin-top: 80px;

    @media (max-width: 1201px) {
      flex-direction: column;
    }

    &--item {
      padding: 14px;
      background: #bf0807;
      border-radius: 8px;
      width: calc(100% / 5);
      height: 138px;

      @media (max-width: 1201px) {
        width: 100%;
        height: 52px;
        display: flex;
        flex-direction: row !important;
        justify-content: space-between !important;
      }

      &:not(:last-child) {
        margin-right: 24px;

        @media (max-width: 1201px) {
          margin-bottom: 8px;
          margin-right: 0;
        }
      }

      p {
        &:first-child {
          margin-bottom: 14px;

          @media (max-width: 1201px) {
            margin-bottom: 0;
          }
        }

        img {
          width: 36px;

          @media (max-width: 1201px) {
            width: 25px;
          }
        }

        font-weight: 500;
        font-size: 18px;
        line-height: 22px;
        text-align: center;
        color: #ffffff;
        margin-bottom: 0;

        &:last-child {
          font-family: Poppins !important;
          font-style: normal;
          font-weight: 600;
          font-size: 28px;
          line-height: 42px;
          text-align: center;
          color: #ffffff;
        }
      }
    }
  }
}
