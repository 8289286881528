* {
  padding: 0;
  margin: 0;
  outline: 0 !important;
  box-sizing: border-box;
  font-family: "Montserrat", sans-serif !important;
  text-decoration: unset !important;
  list-style: none;
}

body {
  background-image: url("../images/back.png");
  background-color: #fafafa;
}

.splide__arrows {
  @media (max-width: 992px) {
    display: none;
  }
}

.show {
  display: unset !important;
}

.btnSendMob {
  width: 34px !important;
  height: 34px !important;
  background: #bf0807 !important;
  border-radius: 100% !important;
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
  right: 20px !important;

  svg {
    margin-right: 0 !important;
  }
}

.opacityNone {
  display: none;
  top: -340px;
  transition: all 0.2s;
}

.image-box {
  img {
    height: auto;
    transform: unset !important;
  }
}

@media (max-width: 722px) {
  .container,
  .container-lg,
  .container-md,
  .container-sm,
  .container-xl {
    max-width: 100%;
  }
}

@media (max-width: 745px) {
  .container_header {
    max-width: 100%;
    padding: 0;
  }
}

@media (max-width: 1201px) {
  .container,
  .container-lg,
  .container-md,
  .container-sm,
  .container-xl {
    max-width: 100%;
  }
}

@media (min-width: 1300px) {
  .container,
  .container-lg,
  .container-md,
  .container-sm,
  .container-xl {
    max-width: 1280px;
  }
}

@media (min-width: 1400px) {
  .container,
  .container-lg,
  .container-md,
  .container-sm,
  .container-xl {
    max-width: 1328px;
  }
}

@media (min-width: 1500px) {
  .container,
  .container-lg,
  .container-md,
  .container-sm,
  .container-xl {
    max-width: 1328px;
  }
}

@media (min-width: 1700px) {
  .container,
  .container-lg,
  .container-md,
  .container-sm,
  .container-xl {
    max-width: 1342px;
  }
}

@media (min-width: 1920px) {
  .container,
  .container-lg,
  .container-md,
  .container-sm,
  .container-xl {
    max-width: 1342px;
  }
}

.title {
  position: relative;
  font-weight: 600;
  font-size: 32px;
  line-height: 39px;
  color: #000000;
  display: inline-flex;
  margin-bottom: 45px;

  @media (max-width: 765px) {
    display: flex;
    justify-content: center;
    font-size: 24px;
  }

  &:after {
    position: absolute;
    content: "";
    left: 0;
    bottom: -10px;
    height: 5px;
    background-color: #b41309;
    width: 50%;

    @media (max-width: 765px) {
      left: 50%;
      transform: translateX(-50%);
    }
  }
}

.btnTop {
  position: fixed;
  right: 24px;
  bottom: 20px;
  background: #bf0807;
  border-radius: 100%;
  width: 49px;
  height: 49px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  opacity: 0;
  transition: 0.2s;
  z-index: 20;
  @media (max-width: 1033px) {
    display: none;
  }
}

.ant-pagination-disabled {
  display: none;
}

.ant-pagination {
  li {
    &:not(.ant-pagination-disabled) {
      border: none;
      width: 24px;
      height: 24px;
      padding: 0;
      display: inline-flex;
      align-self: center;
      justify-content: center;
      border-radius: 100%;
      min-width: auto;
      background-color: transparent;

      &.ant-pagination-item-active {
        background: #bf0807;

        a {
          color: #fff;
        }
      }

      a,
      button {
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 0;
        background-color: transparent;
        border: none;
        font-family: Poppins !important;
        font-style: normal;
        font-weight: normal;
        font-size: 11px;
        line-height: 16px;
        color: #000000;
      }
    }
  }
}

#endMultiSlider {
  height: 300px;

  @media (max-width: 575px) {
    height: auto;
  }
}

.overFlowHidden {
  overflow: hidden;
}

.filterBox {
  width: 100%;
  background: #bf0807;
  box-shadow: 0px 6px 16px rgba(0, 0, 0, 0.2);
  border-radius: 14px;
  height: 47px;
  display: none;
  align-items: center;
  justify-content: center;
  margin-bottom: 25px;

  @media (max-width: 992px) {
    display: flex;
  }

  button {
    svg {
      margin-right: 5px;
    }
    background-color: transparent;
    font-weight: 600;
    font-size: 14px;
    line-height: 17px;
    color: #ffffff;
    flex: none;
    order: 1;
    flex-grow: 0;
    margin: 0px 10px;
    border: none;
  }
}

.layerTitle {
  display: flex;
  align-items: center;
  justify-content: center;
  background: linear-gradient(
      0deg,
      rgba(191, 8, 7, 0.58),
      rgba(191, 8, 7, 0.58)
    ),
    url("../images/aboutLayer.png");
  height: 101px;
  margin-bottom: 159px;
  background-repeat: no-repeat;
  background-size: 100% 100%;

  @media (max-width: 992px) {
    height: 39px;
    margin-bottom: 40px;
  }

  h1 {
    margin-bottom: 0;
    font-weight: bold;
    font-size: 60px;
    line-height: 73px;
    text-align: center;
    color: rgba(255, 255, 255, 0.97);

    @media (max-width: 992px) {
      font-size: 20px;
    }
  }
}

main {
  position: relative;
}

.submitInput {
  background: #bf0807;
  border-radius: 8px;
  width: 100%;
  margin-top: 32px;
  height: 60px;
  color: #fff;
  border: none;
  font-family: Poppins !important;
  font-style: normal;
  font-weight: 600;
  font-size: 17px;
  line-height: 25px;
  color: #ffffff;
}

.searchResult {
  margin-bottom: 150px;

  @media (max-width: 992px) {
    margin-bottom: 50px;
  }
}

.notFoundProducts {
  display: flex;
  justify-content: center;
  color: #bf0807;
  font-size: 30px;
  font-weight: bold;
}
