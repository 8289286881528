.header {
  position: fixed;
  padding: 13px 0;
  left: 0;
  width: 100%;
  z-index: 9;

  &__mobileBox {
    display: flex;
  }

  &__mobileSearch {
    margin-right: 17px;
  }

  &__search {
    display: none;
    height: 100%;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    align-items: center;
    justify-content: center;
    background-color: rgba(0, 0, 0, 0.289);

    &--item {
      position: relative;
      background: rgba(196, 196, 196, 0.48);
      border-radius: 12px;
      width: 609px;
      height: 84px;

      form {
        height: 100%;
      }

      @media (max-width: 992px) {
        height: 50px;
        width: 90%;
      }

      img {
        position: absolute;
        right: 2px;
        top: -26px;
        cursor: pointer;
      }

      input {
        height: 100%;
        width: 100%;
        background-color: transparent;
        border: none;
        padding: 0 20px;
        font-size: 24px;
        line-height: 29px;
        color: rgba(255, 255, 255, 0.62);
        @media (max-width: 992px) {
          font-size: 18px;
        }

        &::placeholder {
          font-size: 24px;
          line-height: 29px;
          color: rgba(255, 255, 255, 0.62);

          @media (max-width: 992px) {
            font-size: 18px;
          }
        }
      }

      button {
        position: absolute;
        right: 20px;
        top: 50%;
        transform: translateY(-50%);
        cursor: pointer;
        background-color: transparent;
        border: none;

        svg {
          width: 100%;
          height: 100%;
        }

        @media (max-width: 992px) {
          width: 20px;
        }
      }
    }
  }

  &__logo {
    width: 180px;

    @media (max-width: 1201px) {
      width: 150px;
    }

    @media (max-width: 765px) {
      width: 90px;
    }
  }

  &__mobMenu {
    position: fixed;
    right: 0;
    height: auto;
    width: 80%;
    padding: 29px 20px;
    background: #ffffff;
    border-radius: 24px 0px 0px 24px;
    z-index: 555;

    &--close {
      position: absolute;
      left: -45px;
    }

    &--social {
      margin-top: 25px;
      display: flex;
      justify-content: space-between;
    }

    > p {
      display: flex;
      border-bottom: 1px solid #bf0807;
      padding-bottom: 9px;

      span {
        font-weight: bold;
        font-size: 12px;
        line-height: 15px;
        color: rgba(0, 0, 0, 0.23);

        &.active {
          color: #bf0807;
        }

        &:not(:last-child) {
          margin-right: 15px;
        }
      }
    }
  }

  &__mobLink {
    display: flex;
    flex-direction: column;

    a {
      svg {
        margin-right: 9px;
      }

      &:not(:last-child) {
        margin-bottom: 12px;
      }

      font-weight: 600;
      font-size: 14px;
      line-height: 17px;
      color: #000000;
    }

    .menuMob {
      flex-direction: column;
      padding-left: 26px;
      a {
        font-weight: 300;
        font-size: 10px;
        line-height: 12px;
        color: #000000;
      }
      margin-bottom: 11px;
      display: none;
    }

    .mobParent {
      p {
        svg {
          margin-right: 9px;
        }

        &:not(:last-child) {
          margin-bottom: 12px;
        }

        font-weight: 600;
        font-size: 14px;
        line-height: 17px;
        color: #000000;
      }

      &.showMenu {
        .menuMob {
          display: flex;
        }
      }
    }
  }

  &__nav {
    width: 900px;
    display: flex;
    justify-content: center;

    nav {
      display: flex;
    }

    &--item {
      font-weight: 500;
      font-size: 16px;
      line-height: 20px;
      color: #fff;
      transition: all 0.2s;

      @media (max-width: 1320px) {
        font-size: 14px;
      }

      &.active {
        font-weight: 700;
      }

      &:hover {
        color: #fff;
        font-weight: 700;
        transition: all 0.2s;
      }

      &:not(:last-child) {
        margin-right: 27px;

        @media (max-width: 1320px) {
          margin-right: 17px;
        }
      }
    }
  }

  &__langBox {
    &--search {
      margin-right: 13px;

      button {
        width: 50px;
        height: 50px;
        background: rgba(244, 244, 244, 0.62);
        border-radius: 8px;
        border: none;
        @media (max-width: 1201px) {
          width: 45px;
          height: 45px;
        }
      }
    }

    &--multiLang {
      width: 50px;
      height: 50px;
      background: rgba(244, 244, 244, 0.62);
      border-radius: 8px;
      border: none;
      position: relative;

      @media (max-width: 1201px) {
        width: 45px;
        height: 45px;
      }

      &:hover {
        .header__langBox--multiBox {
          display: flex;
          flex-direction: column;
        }
      }

      button {
        font-weight: bold;
        font-size: 16px;
        line-height: 24px;
        color: rgba(0, 0, 0, 0.69);
        font-family: "Poppins", sans-serif !important;
        border: none;
        background-color: transparent;
        display: flex;
        align-items: center;

        svg {
          margin-left: 5px;
        }
      }
    }

    &--multiBox {
      position: absolute;
      width: 50px;
      display: none;
      top: 48px;
      padding: 5px 0;
      padding-left: 8px;

      button {
        font-family: "Poppins", sans-serif !important;
      }
    }
  }
}

#inHeader {
  position: relative;
  background-color: #fff;

  .header__mobileBox {
    svg {
      path {
        fill: #000 !important;
      }
    }
  }

  nav {
    a {
      color: #000;
    }
  }
}

.layoutData {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.6);
  z-index: 5;
}

.fixedHeader {
  position: fixed !important;
  background-color: #bf0807 !important;
  opacity:0.8;
  top: 0;
  transition: all 0.4s ease-in-out;

  a {
    color: #fff !important;
  }
  
}
.youtube-icon{
   
      fill:#BF0807;
      width:18px;
      height: 18px;
    
  }