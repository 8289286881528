.loading {
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  -moz-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  -o-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);

  #loading-bar-spinner.spinner {
    z-index: 19 !important;
    animation: loading-bar-spinner 400ms linear infinite;
  }

  #loading-bar-spinner.spinner .spinner-icon {
    width: 40px;
    height: 40px;
    border: solid 4px transparent;
    border-top-color: #941001 !important;
    border-left-color: #941001 !important;
    border-radius: 50%;
  }

  @keyframes loading-bar-spinner {
    0% {
      transform: rotate(0deg);
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
      transform: rotate(360deg);
    }
  }
}
