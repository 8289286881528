.footer {
  position: relative;
  background: #941001;
  border: 1px solid rgba(0, 0, 0, 0.07);
  padding: 78px 0;

  &__sendMessage {
    position: fixed;
    right: 88px;
    bottom: 0;
    z-index: 999;

    @media (max-width: 992px) {
      right: 20px;
      bottom: 20px;
    }

    > button {
      padding: 5px 0;
      display: flex;
      align-items: center;
      justify-content: center;
      background: #fbfbfb;
      border: 1px solid #bf0807;
      border-radius: 8px;
      font-weight: 600;
      font-size: 16px;
      line-height: 22px;
      color: #000000;
      width: 288px;

      @media (max-width: 1033px) {
        right: 50px;
      }

      svg {
        margin-right: 15px;
      }
    }
  }

  &__sendMessageBox {
    position: absolute;
    top: -340px;
    background-color: #fff;
    width: 100%;
    height: 375px;
    border-radius: 8px;
    transition: all 0.2s;

    @media (max-width: 1223px) {
      left: -262px;
      width: 300px;
    }
  }

  &__sendTitle {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: #bf0807;
    padding: 5px 8px;
    border-top-right-radius: 8px;
    border-top-left-radius: 8px;

    button {
      background-color: transparent;
      border: none;
    }

    h3 {
      font-size: 14px;
      line-height: 17px;
      color: #ffffff;
      margin-bottom: 0;
    }
  }

  &__sendForm {
    display: flex;
    flex-direction: column;
    padding: 10px;
    background-color: #fff;

    input {
      background: #ffffff;
      border: 0.5px solid #dcdcdc;
      box-sizing: border-box;
      border-radius: 5px;
      width: 100%;
      padding: 0 5px;
      height: 35px;

      &:not(:last-child) {
        margin-bottom: 15px;
      }
    }

    textarea {
      background: #ffffff;
      border: 0.5px solid #dcdcdc;
      box-sizing: border-box;
      border-radius: 5px;
      width: 100%;
      margin-bottom: 25px;
      height: 96px;
      padding: 0 5px;
    }

    .sendBtnFormFooter {
      position: absolute;
      bottom: 20px;
      width: 90%;
      left: 0;
      transform: translateX(-50%);
      left: 50%;
      background: #bf0807;
      border: 0.5px solid #dcdcdc;
      box-sizing: border-box;
      border-radius: 5px;
      color: #fff;
      font-weight: 600;
      font-size: 14px;
      line-height: 17px;
      height: 41px;

      svg {
        margin-right: 15px;
      }
    }
  }

  @media (max-width: 1033px) {
    padding: 30px 0;
  }

  &__items {
    width: calc(100% / 4);
    display: flex;
    flex-direction: column;

    h3 {
      font-weight: bold;
      font-size: 19px;
      line-height: 23px;
      color: #ffffff;
      margin-bottom: 22px;
    }

    a {
      font-weight: 300;
      font-size: 16px;
      line-height: 20px;
      color: #ffffff;
      display: inline-flex;
      align-items: flex-start;

      @media (max-width: 575px) {
        font-size: 10px;
      }

      img {
        margin-right: 18px;
      }

      &:not(:last-child) {
        margin-bottom: 18px;
      }
    }

    &:not(:last-child) {
      padding-right: 20px;
    }
  }
  &__end-left {
    p {
      color: #ffffff;
      margin-top: 20px;
       @media (max-width: 765px) {
        font-size: 10px;
      }
    }
    a {
      color: #ffffff;
      margin: 0 5px;
      &:hover{
        color:rgb(233, 195, 125);
      }
    }
  }

  &__end {
    p {
      font-weight: 300;
      font-size: 12px;
      line-height: 15px;
      color: #ffffff;
      opacity: 0.6;
      margin-bottom: 0;
    }

    a {
      &:not(:last-child) {
        margin-right: 17px;
      }
    }

    &:last-child {
      @media (max-width: 1033px) {
        margin-bottom: 25px;
      }
    }
  }

  &__margin {
    margin-top: 101px;

    @media (max-width: 1033px) {
      display: flex;
      flex-direction: column-reverse;
      margin-top: 50px;
    }
  }

  .hide {
    @media (max-width: 1033px) {
      display: none;
    }
  }

  .flexBoxFooter {
    @media (max-width: 1033px) {
      justify-content: center !important;
    }

    .footer__items {
      &:last-child {
        @media (max-width: 1033px) {
          display: flex;
          flex-wrap: wrap;
          width: 100%;
        }

        h3 {
          @media (max-width: 1033px) {
            text-align: center;
          }
        }

        .d-flex {
          @media (max-width: 1033px) {
            flex-direction: row !important;
            flex-wrap: wrap;
          }

          a {
            &:first-child {
              @media (max-width: 1033px) {
                padding-right: 15px;
              }
            }
          }
        }
      }
    }
  }
}
