.partnerSlider {
    margin-top: 94px;
    
    .splide__arrow {
      background-color: transparent;
  
      &--prev {
        left: -2em;
      }
  
      &--next {
        right: -2em;
      }
    }
  }
  
  #endPartnerSlider {
    margin-bottom: 150px;
  
    @media (max-width: 992px) {
      margin-bottom: 50px;
    }
  }
  .partnerSwiper {
    width: 100%;
    height: 100%;
    margin-bottom: 100px;
  }
  
  .partnerSliderContent {
    text-align: center;
    font-size: 18px;
    // background: #fff;
    height: 300px !important;
    /* Center slide text vertically */
    display: -webkit-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    -webkit-justify-content: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    -webkit-align-items: center;
    align-items: center;
    overflow: hidden;
    // &:hover{
    //   cursor: zoom-in;
    //   img{
    //     transform: scale(1.2);
    //   }
    // }
    
  }
  
  .partnerSliderContent img {
    display: block;
    width: 100%;
    height: 100%;
    object-fit: cover;
    transition: .5s ease;
   
  }
  @media screen and (max-width:576px) {
    .partnerSliderContent{
      height: 250px;
      img{
        height: 100%;
      }
    }
  }

//   Options -MultiViews
// RewriteEngine On
// RewriteCond %{REQUEST_FILENAME} !-f
// RewriteRule ^ index.html [QSA,L]